import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { PALETTE, BOX_SHADOW } from "../consts/Colors";
import { GoldenLogo } from "../consts/SVG";

export default function Header() {
  return (
    <HeaderContainer>
      <HeaderDiv>
        <Link to="/">
          <SingleMenu>Home</SingleMenu>
        </Link>
        <a href="/#location">
          <SingleMenu>Location</SingleMenu>
        </a>
        <Link to="/">
          <LogoDiv>{GoldenLogo}</LogoDiv>
        </Link>
        <a href="/#about">
          <SingleMenu>About</SingleMenu>
        </a>
        <Link to="/menu">
          <SingleMenu>Menu</SingleMenu>
        </Link>
      </HeaderDiv>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  position: sticky;
  left: 0px;
  top: 0px;
  padding: 0px 0px;
  background-color: ${PALETTE.HEADER_BG};
  color: ${PALETTE.HEADER_TEXT};
  z-index: 20;
  box-shadow: ${BOX_SHADOW};
  scroll-margin-top: 100px;
`;

const HeaderDiv = styled.div`
  padding: 10px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${PALETTE.HEADER_TEXT};
  @media (max-width: 600px) {
    padding: 10px 10px;
  }
`;

const LogoDiv = styled.div`
  padding-left: 40px;
  & :hover {
    animation: beat 0.5s infinite alternate;
    @keyframes beat {
      to {
        transform: scale(1.1);
      }
    }
  }

  @media (max-width: 600px) {
    width: 55px;
    height: 30 px;
    padding-left: 10px;
  }
`;

const SingleMenu = styled.div`
  padding-left: 40px;
  font-weight: 700;
  font-size: 1em;
  cursor: pointer;
  color: ${PALETTE.HEADER_TEXT};
  & :hover {
    transform: scale(1.1);
    color: ${PALETTE.HEADER_TEXT};
  }
  @media (max-width: 600px) {
    padding-left: 10px;
    font-size: 0.75em;
  }
`;
