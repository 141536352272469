export const PALETTE = {
  WHITE: "#dddddd",
  BROWN: "#D4A373",
  BROWN_TEXT: "#96644F",
  CREAM: "#FFE8D6",
  SKIN: "#FFDDD2",
  DARK_GREEN: "#006D77",
  
  PURE_WHITE: "#ffffff",
  BACKGROUND: "#F3EDEB",
  HEADER_BG: "#FEF7F4",
  HEADER_TEXT: "#E5BDAD",
};

export const BOX_SHADOW =
  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
